<!-- EditBackfillPool.vue -->
<template>
  <div>
    <v-toolbar height="50" flat color="white">
      <v-toolbar-title>Backfill Pools</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-dialog persistent v-model="$store.state.backfillPools.slotDialog" :max-width="editedItem.type == 'feed' ? '1200px' : '1500px'" scrollable>
        <template v-slot:activator="{ on }">
          <v-btn color="primary" dark class="ml-2" v-on="on" :disabled="readOnlyPools"
            >New Pool <v-icon right>mdi-pool</v-icon>
          </v-btn>
          <v-form v-show="isEditing" @submit.prevent="submitForm" id="submit">
            <v-btn
              color="success"
              @click="submitForm"
              :disabled="submitting || readOnlyPools"
              >Save Pools <v-icon right>mdi-content-save</v-icon>
            </v-btn>
          </v-form>
        </template>

        <!-- Actions Edit Form -->
          <app-backfill-pools-edit-percent v-if="editPercent" :closeDialog="closeDialog" :updatePools="updatePools"></app-backfill-pools-edit-percent>
          <!--Also shown for new pools-->
          <app-backfill-pools-edit-pool v-else :addUserActivity="addUserActivity" :closeDialog="closeDialog" :updatePools="updatePools" :feedId="feedId"></app-backfill-pools-edit-pool>
        <!-- End of Actions Edit Form -->
      </v-dialog>
    </v-toolbar>
  </div>
</template>
<!-- End of EditBackfillPool.vue -->

<script>
import { mapMutations } from "vuex";
import BackfillPoolsEditPool from "@/components/medusa/BackfillPoolsEditPool";
import BackfillPoolsEditPercent from "@/components/medusa/BackfillPoolsEditPercent";
export default {
  props: ["isValid", "feedId"],
  components: {
    appBackfillPoolsEditPool: BackfillPoolsEditPool,
    appBackfillPoolsEditPercent: BackfillPoolsEditPercent
  },
  data: () => ({
    items: [],
    submitting: false,
  }),
  computed: {
    editedIndex() {
      return this.$store.state.backfillPools.editedIndex;
    },
    editedItem() {
      return this.$store.state.backfillPools.editedItem;
    },
    editPool() {
      return this.$store.state.backfillPools.editPool;
    },
    editPercent() {
      return this.$store.state.backfillPools.editPercent;
    },
    formTitle() {
      return this.editedIndex === -1
        ? "New Backfill Pool"
        : this.editPool
        ? "Edit Backfill Pool"
        : "Edit Pool Percentage";
    },
    initialPercent() {
      return this.$store.state.backfillPools.initialPercent;
    },
    isEditing() {
      return this.$store.state.backfillPools.isEditing;
    },
    lockedPercent() {
      return this.$store.state.backfillPools.lockedPercent;
    },
    newUnsavedPools() {
      return this.$store.state.backfillPools.newUnsavedPools;
    },
    origPools() {
      return this.$store.state.backfillPools.origPools;
    },
    pools() {
      return this.$store.state.backfillPools.pools;
    },
    readOnlyPools() {
      return this.$store.state.backfillPools.readOnlyPools;
    },
    slotDialog() {
      return this.$store.state.backfillPools.slotDialog;
    },
    unsavedFeeds() {
      return this.$store.state.backfillPools.unsavedFeeds;
    },
    userActivity() {
      return this.$store.state.backfillPools.userActivity;
    }
  },
  methods: {
    ...mapMutations({
      resetUserActivity: "backfillPools/resetUserActivity",
      setHistoryLogLoaded: "backfillPools/setHistoryLogLoaded",
      setIsEditing: "backfillPools/setIsEditing",
      setEditPool: "backfillPools/setEditPool",
      setEditPercent: "backfillPools/setEditPercent",
      setLockedPercent: "backfillPools/setLockedPercent",
      setPools: "backfillPools/setPools",
      setSlotDialog: "backfillPools/setSlotDialog",
      setUnsavedFeeds: "backfillPools/setUnsavedFeeds"
    }),
    /**
     * Set and push user activity actions to the userActivity array
     *
     * @param {string} user
     *
     * @param {string} activity
     */
    addUserActivity(user, activity) {
      let action = [
        {
          user: user,
          activity: activity
        }
      ];
      Array.prototype.push.apply(this.userActivity, action);
    },
    /**
     * On Form Submit, Compare the initial Pools state and the new saved state, and log all user changes
     */
    logPoolTableActivity() {
      let user = this.$store.state.auth.user.username;
      for (var newPool of this.pools) {
        let oldPoolIndex = this.origPools.findIndex((x) => x.pool_id == newPool.pool_id);
        let oldPool = this.origPools[oldPoolIndex];
        if (oldPool) {
          //Compare Pool Percentage
          if (newPool.percentage != oldPool.percentage) {
            this.addUserActivity(
              user,
              `Pool <b>${newPool.name}</b> <u>percentage</u> changed from <b>${oldPool.percentage}%</b> to <b>${newPool.percentage}%</b>`
            );
          }
          //Compare Pool Enabled
          if (newPool.enabled != oldPool.enabled) {
            let enabled = "enabled";
            if (newPool.enabled) enabled = "disabled";
            this.addUserActivity(user, `Pool <b>${newPool.name}</b> ${enabled} `);
          }
          //Compare Pool Lock
          if (newPool.locked != oldPool.locked) {
            let locked = "unlocked";
            if (newPool.locked) locked = "locked";
            this.addUserActivity(user, `Pool <b>${newPool.name}</b> ${locked}`);
          }
        } else {
          this.addUserActivity(
            user,
            `Pool Added: <b>Name:</b> ${newPool.name} <b>Percentage:</b> ${newPool.percentage} <b>Enabled:</b> ${newPool.enabled} <b>Locked</b>: ${newPool.locked}`
          );
        }
      }
      return this.userActivity;
    },
    closeDialog(closeCase) {
      if(this.unsavedFeeds){
        var r = confirm("Leave Without Saving Feeds?");
        if (r != true) {
          return;
        } else {
          this.setUnsavedFeeds(false);
          this.resetUserActivity();
        }
      }
      this.setEditPool(false);
      this.setEditPercent(false);
      this.setSlotDialog(false);
      this.$nextTick(() => {
        if (closeCase == "update") {
          this.$store.dispatch("backfillPools/resetDefaultItem");
        } else if (closeCase == "cancel") {
          this.editedItem.items = Number(this.startingItemCount);
          let index = JSON.parse(JSON.stringify(this.editedIndex));
          this.$store.dispatch("backfillPools/resetEditedItem", { index: index });
        }
      });
    },
    /**
     * Recalculate the total locked percentage
     */
    reCalculateLockedPercent() {
      if (this.editedItem.locked) {
        this.setLockedPercent(Number(this.lockedPercent + this.editedItem.percentage));
      } else {
        this.setLockedPercent(Number(this.lockedPercent - this.editedItem.percentage));
      }
    },
    
    /**
     * Submit the form and save all pool data
     */
    submitForm() {
      if (!this.isValid) {
        alert("Total Percent Must Be 100% Before You Can Save");
        return;
      }
      this.submitting = true;
      //Log User Activity
      this.logPoolTableActivity();
      //Submit Data
      this.axios
        .put("/medusa/pools/save_pools", {
          pools: this.pools,
          userActivity: this.userActivity
        })
        .then(() => {
          this.$store.commit("sendAlert", {
            msg: "Form Saved",
            color: "success"
          });
          //Resort List By Percentage Desc, and reset origPools and userActivity
          this.setPools(
            this.pools.sort((a, b) =>
              b.percentage == a.percentage
                ? b.name < a.name
                  ? 1
                  : -1
                : b.percentage > a.percentage
                ? 1
                : -1
            )
          );
          //Reset Values back to their defaults
          this.$store.dispatch("backfillPools/resetOrigPools");
          this.setHistoryLogLoaded(false);
          this.resetUserActivity();
          this.$store.dispatch("backfillPools/clearNewUnsavedPools");
          this.$store.dispatch("medusaCampaigns/purgeMedusaCache", { cacheName: "spotBackfillPoolsCache", keys: null, keysSupplement: 'all' });
        })
        .finally(() => {
          this.submitting = false;
          this.setIsEditing(false);
        });
    },
    /**
     * Call BackfillPoolsTable SaveDialog
     */
    updatePools() {
      //Make sure new pool name is unique
      if (!this.editPercent && !this.editPool) {
        let uniqueName = this.pools.filter((pool) => pool.name == this.editedItem.name);
        if (uniqueName.length > 0) {
          this.$store.commit("sendAlert", {
            msg: "Pool Name Must Be Unique",
            color: "error",
            position: "top"
          });
          return;
        }
      }
      if(this.editedIndex > -1){
        let origName = this.pools[this.editedIndex].name;
        //Check to see if name was updated, if so, update it in the newUnsavedPools array
        if(this.newUnsavedPools.includes(origName) && origName != this.editedItem.name){
          let index = this.newUnsavedPools.indexOf(origName);
          this.newUnsavedPools.splice(index,1);
          this.newUnsavedPools.push(this.editedItem.name);
        }
      }
      this.$emit("poolSaved", this.editedIndex, this.editedItem);
      this.closeDialog("update");
    },
  }
};
</script>

<style scoped lang="scss">
</style>