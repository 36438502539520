<template>
  <v-row justify="center" class="mb-1">
    <v-toolbar height="50" flat color="white">
    <v-spacer></v-spacer>
    <v-dialog :retain-focus="false" persistent v-model="$store.state.backfillPools.mappingDialog" scrollable max-width="500px" max-height="800px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" dark @click="bindMapping()" class="ml-2 addFeedBtn" v-on="on" :disabled="readOnlyPools || submitting"
          >Feed Item Mapping <v-icon right>mdi-sitemap</v-icon>
        </v-btn>
      </template>
      <v-card class="geoCard">
        <v-card-title>Feed Asset Mapping</v-card-title>

        <v-card-text style="height: 500px" class="pl-0 pr-0 pb-0">
          <template v-if="overlay">
            <v-overlay :value="overlay">
              <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
          </template>

          <template>
            <v-divider></v-divider>

            <v-alert
              class="mb-0 pt-1 pb-1"
              transition="scale-transition"
              type="info"
              border="left"
              elevation="0"
              colored-border
              dense
              v-if="feed.assetFields"
            >
              Found <span><b>{{feed.assetFields.length}} Unmatched Item Fields.</b><br>Please match these fields, if applicable</span>
            </v-alert>
            <v-divider></v-divider>
            <v-row v-for="(field,index) in feed.assetFields" :key="index" align="center" justify="center" class="ml-0 mr-0 mb-0 mt-0">

              <v-col cols="4" class="pt-0 pb-0">
                <h3>{{field}}</h3>
              </v-col>

              <v-col cols="2" class="pt-0 pb-0">
                <v-icon>mdi-arrow-left-right-bold</v-icon>
              </v-col>

              <v-col cols="4" class="pt-0 pb-0">
                <v-select
                  @change="updateFilter"
                  :disabled="readOnlyPools || submitting"
                  :items="filteredFieldSelectList"
                  :menu-props="{closeOnContentClick: true }"
                  v-model="feed.mapping[field]">

                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @change="resetField(field)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          <b>Clear Selection</b>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </template>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="feed.feedId" color="blue darken-1" text @click="closeMappingDialog('cancel')">Cancel</v-btn>
          <v-btn
            @click="closeMappingDialog()"
            color="blue darken-1"
            :disabled="readOnlyPools || submitting"
            text
            >Apply</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-toolbar>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  props: ['assetFields','feedFields', 'submitting'],
  data: () => ({
    filterValues: [],
    mappedFields: [],
    originalMapping: null,
    overlay: false,
  }),
  computed: mapState({
    defaultFeed(){
      return this.$store.state.backfillPools.defaultFeed;
    },
    editedFeedIndex(){
      return this.$store.state.backfillPools.editedFeedIndex;
    },
    editedIndex() {
      return this.$store.state.backfillPools.editedIndex;
    },
    editedItem() {
      return this.$store.state.backfillPools.editedItem;
    },
    feed() {
      if(this.editedFeedIndex > -1)
        return this.editedItem.typeData[this.editedFeedIndex];
      else
        return this.defaultFeed;
    },
    // Sets disabled property if parameter has already been selected
    filteredFieldSelectList() {
      return this.feed.feedFields.map((listItem) => ({
        text: listItem,
        value: listItem,
        disabled: this.filterValues.includes(listItem)
      }));
    },
    readOnlyPools() {
      return this.$store.state.backfillPools.readOnlyPools;
    },
  }),

  methods: {
    ...mapMutations({
      setMappingDialog: "backfillPools/setMappingDialog",
      setUnsavedMapping: "backfillPools/setUnsavedMapping",
    }),
    bindMapping(){
      this.filterValues = Object.values(this.feed.mapping);
      if(this.feed.feedId){
        this.originalMapping = JSON.parse(JSON.stringify(this.feed.mapping));
      }
      
    },
    closeMappingDialog(action = null){
      if(action == 'cancel'){
        this.feed.mapping = this.originalMapping;
        this.setUnsavedMapping(false);
      }
      this.setMappingDialog(false);
    },
    mapField(key, field){
      this.feed.mapping[key] = field;
    },
    resetField(key){
      this.feed.mapping[key] = null;
      this.filterValues = Object.values(this.feed.mapping);

    },
    updateFilter(){
      this.setUnsavedMapping(true);
      this.filterValues = Object.values(this.feed.mapping);
    }
  },
};
</script>
<style scoped lang="scss">
  ::v-deep .v-card__title{
    background: #005eb6;
    color: white;
  }
  ::v-deep .v-alert__border{
    border-top-left-radius: revert !important;
    border-bottom-left-radius: revert !important;
  }
</style>