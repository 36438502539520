<!-- EditBackfillPool.vue -->
<template>
  <div>
    <!-- Actions Edit Form -->
    <v-form ref="form" v-model="editFormIsValid">
      <v-card>
        <v-card-title class="pb-0">
          <p class="headline card-title">{{ formTitle }}</p>
          <app-backfill-pools-edit-feed-url v-if="editedItem.type == 'feed'" :origFeedTypeData="origFeedTypeData" :feedId="feedId" :submitting="submitting"></app-backfill-pools-edit-feed-url>
        </v-card-title>

        <v-card-text class="pb-0" :class="{'fullForm' : editedItem.type, 'shortForm' : !editedItem.type}">
          <div class="pt-0 pb-0" :class="{'container' : editedItem.type == 'feed'}">
            <v-row align="center" justify="center" class="ml-0 mr-0 mb-0 mt-0">
              <!-- Pool Type -->
              <v-col class="py-2 pb-0" cols="8" sm="6" md="3" >
                <v-btn-toggle
                  :class="{ 'read-only': editedItem.typeSet || editedItem.typeData.length > 0 }"
                  borderless
                  dense
                  :readonly="readOnlyPools"
                  v-model="editedItem.type"
                  tile
                  color="deep-purple accent-3"
                >
                  <v-btn value="feed">Feed</v-btn>
                  <v-btn value="doc">Doc</v-btn>
                </v-btn-toggle>
              </v-col>

              <!-- Pool Name -->
              <v-col cols="12" sm="6" md="4" class="pb-0" >
                <v-text-field
                  v-model="editedItem.name"
                  label="Pool"
                  min="5"
                  :readonly="readOnlyPools"
                  :rules="[rules.max30chars, rules.min5chars]"
                ></v-text-field>
              </v-col>

              <!-- Percentage (only shows when adding a new pool) -->
              <v-col
                cols="12"
                :sm="6"
                :md="5"
                class="pb-0"
                v-if="!editPool"
              >
                <v-slider
                  :readonly="!!editedItem.locked || readOnlyPools"
                  class="pt-6"
                  :thumb-size="24"
                  thumb-label="always"
                  v-model="editedItem.percentage"
                  :max="
                    editedItem.enabled && editedItem.locked
                      ? 100 - lockedPercent + Number(initialPercent)
                      : 100 - lockedPercent
                  "
                >
                  <template v-slot:append>
                    <v-text-field
                      v-model="editedItem.percentage"
                      class="mt-0 pt-0"
                      hide-details
                      single-line
                      type="number"
                      style="width: 60px"
                    ></v-text-field>
                  </template>
                </v-slider>
              </v-col>

              <!-- TypeData -->
              <v-col
                v-if="editedItem.type"
                cols="12"
                sm="12"
                md="12"
                lg="12"
                align="start"
              >

                <v-row id="TypeDataContent" class="ml-0 mr-0 mb-0 mt-0">
                  <v-col cols="12" class="pt-0 pb-0" :class="{'pl-0 pr-0': editedItem.type == 'feed'}">
                    <div>
                      <!-- Doc AutoComplete typeData -->
                      <app-backfill-pools-autocomplete
                        v-if="editedItem.type == 'doc'"
                      ></app-backfill-pools-autocomplete>
                      <!---------------------------------------------------------------
                          Docs Table
                      ----------------------------------------------------------------->
                      <v-data-table v-if="editedItem.type == 'doc'"
                        loading-text="Loading"
                        :headers="docHeaders"
                        height="inherit"
                        :items="editedItem.typeData"
                        no-data-text="No Docs Added"
                        :footer-props="{ 'items-per-page-options': [15, 30, 50, -1] }"
                        class="docsTable elevation-1"
                      >
                        <template v-slot:item.remove="{ item }">
                          <v-btn icon @keydown.enter.prevent @click.native="readOnlyPools ? '' : removeTypeData(editedItem.typeData.indexOf(item))">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </template>

                        <template v-slot:item.DocTitle="{ item }">
                          <span :class="{ strike: item.Active == false || item.DocStatusId != '1' }"> {{item.DocTitle}} </span>
                        </template>

                        <template v-slot:item.DocId="{ item }">
                           <a :href="documentUrl + item.DocId" target="_blank"><span :class="{ strike: item.Active == false || item.DocStatusId != '1' }"> {{item.DocId}} </span></a>
                        </template>

                        <template v-slot:item.OrgName="{ item }">
                          <span :class="{ strike: item.Active == false || item.DocStatusId != '1' }"> {{item.OrgName}} </span>
                        </template>

                        <template v-slot:item.LineOrderNumber="{ item }">
                            <a :href="lineorderUrl + item.LineOrderNumber" target="_blank"><span class="red--text" :class="{ strike: item.Active == false || item.DocStatusId != '1' }">{{ item.LineOrderNumber }}</span></a>
                        </template>

                        <!-- Convert dates to local timezones -->
                        <template v-slot:item.dateAdded="{ item }">
                          <span :class="{ strike: item.Active == false || item.DocStatusId != '1' }"> {{ item.dateAdded != null ? new Date(item.dateAdded.replace(/-/g, '/') + ' GMT+8').toLocaleString() : "-"}} </span>
                        </template>

                        <template v-slot:item.ModifiedDate="{ item }">
                          <span :class="{ strike: item.Active == false || item.DocStatusId != '1' }"> {{ item.ModifiedDate != null ? new Date(item.ModifiedDate.replace(/-/g, '/') + ' GMT+8').toLocaleString() : "-"}} </span>
                        </template>

                        <template v-slot:item.DocPubDate="{ item }">
                          <span :class="{ strike: item.Active == false || item.DocStatusId != '1' }"> {{ item.DocPubDate != null ? new Date(item.DocPubDate.replace(/-/g, '/') + ' GMT+8').toLocaleDateString() : "-"}} </span>
                        </template>
<!--
                        <template v-slot:expanded-item="{ headers, item }">
                            <span :colspan="headers.length" class="doc-lineorder" v-if="item.LineOrderNumber">* Document Sponsored By Line Order: {{ item.LineOrderNumber }}</span>
                        </template>
-->

                        <!-- Used to hide data table expand icons -->
                        <template v-slot:item.data-table-expand="{}"></template>

                      </v-data-table>

                      <!---------------------------------------------------------------
                          Feed Table
                      ----------------------------------------------------------------->
                      <div id="feedTable" v-if="editedItem.type == 'feed'">
                        <div id="feedHeaders">
                          <v-row class="ml-0 mr-0 mb-0 mt-0">
                            <v-col cols="1"></v-col>
                            <v-col cols="5" class="text-left">Feed Url</v-col>
                            <v-col cols="1" class="text-center">Count</v-col>
                            <v-col cols="3" class="text-center">Last Ingested Date</v-col>
                            <v-col cols="2" class="text-center">Date Added</v-col>
                          </v-row>
                        </div>
                        <div id="feedData">
                          <v-row id="feed" class="ml-0 mr-0 mb-0 mt-0"
                              v-for="(feed, index) in editedItem.typeData"
                              :key="index"
                              :index="index">

                            <v-col cols="1" class="pt-0 pb-0">
                              <v-btn icon @click="readOnlyPools ? '' : removeTypeData(index)">
                                <v-icon>mdi-close</v-icon>
                              </v-btn>
                            </v-col>

                            <v-col @click="submitting ? '' : editFeed(index)" :readonly="submitting" cols="5" class="pt-0 pb-0 mt-2">{{ feed.feedUrl }}</v-col>
                            <v-col @click="submitting ? '' : editFeed(index)" :readonly="submitting" cols="1" class="pt-0 pb-0 mt-2 text-center">{{ feed.assetCount ? feed.assetCount : 0 }}</v-col>

                            <v-col @click="submitting ? '' : editFeed(index)" :disabled="submitting" cols="3" class="pt-0 pb-0 mt-2 text-center">
                              {{ feed.lastIngestionDate != null ? new Date(feed.lastIngestionDate.replace(/-/g, '/') + ' GMT+8').toLocaleString() : "-"}}
                            </v-col>
                            <v-col @click="submitting ? '' : editFeed(index)" :disabled="submitting" cols="2" class="pt-0 pb-0 mt-2 text-center">
                              {{ feed.dateAdded != null ? new Date(feed.dateAdded.replace(/-/g, '/') + ' GMT+8').toLocaleString() : "-"}}
                            </v-col>
                            <v-row id="feedDefaults" class="pt-1 ml-0 mr-0 mb-0 mt-0" @click="submitting ? '' : editFeed(index)" :readonly="submitting">
                              <v-col cols="3" class="text-right pt-0 pb-1"><b>Default Org:</b></v-col>
                              <v-col cols="3" class="text-left  font-weight-light pt-0 pb-1 pl-0">{{feed.defaultOrg}}</v-col>
                              <v-col cols="3" class="text-right pt-0 pb-1"><b>Default Content Type:</b></v-col>
                              <v-col cols="3" class="text-left  font-weight-light pt-0 pb-1 pl-0">{{feed.defaultCT}}</v-col>
                              <v-col cols="3" class="text-right pt-0 pb-1"><b>Default CTA:</b></v-col>
                              <v-col cols="3" class="text-left  font-weight-light pt-0 pb-1 pl-0">{{feed.defaultCTA}}</v-col>
                              <v-col cols="3" class="text-right pt-0 pb-1"><b>Override:</b></v-col>
                              <v-col cols="3" class="text-left  font-weight-light pt-0 pb-1 pl-0">{{feed.override == 1 ? "True" : "False"}}</v-col>
                              <v-col cols="3" class="text-right pt-0 pb-1"><b>Geo Tracking:</b></v-col>
                              <v-col cols="3" class="text-left  font-weight-light pt-0 pb-1 pl-0">{{feed.geo_inclusive == 1 ? "Inclusive" : "Exclusive"}}</v-col>
                              <v-col cols="3" class="text-right pt-0 pb-1"><b>Country Count:</b></v-col>
                              <v-col cols="3" class="text-left  font-weight-light pt-0 pb-1 pl-0">{{feed.selectedCountries.length}}</v-col>
                              <v-col cols="12" class="pt-0 pb-0 pl-0 pr-0 mt-0 mb-0 targeting-notes">
                                <v-col cols="8" offset="2" class="pt-0 pb-0 mb-5" v-if="feed.targeting_notes && feed.targeting_notes.length > 0">
                                  <p class="text-center mb-0"><b><u>Targeting Notes:</u></b></p>
                                  <p class="text-center">{{feed.targeting_notes}}</p>
                                </v-col>
                              </v-col>
                            </v-row>
                            <v-row v-if="feed.valid == '0'" class="pt-0 pb-0 pl-0 pr-0 mt-0 ml-0 mr-0 mb-0">
                              <v-col cols="12" class="pt-0 pb-0 pl-0 pr-0 mt-0 ml-0 mr-0 mb-0">
                                <v-alert
                                  class="mt-0 ml-0 mr-0 mb-0 pt-1 pb-1"
                                  dense
                                  icon="mdi-alert-octagram"
                                  type="warning"
                                  elevation="2">
                                  Feed Assets No Longer Exist, Or The Feed URL No Longer Exists For This Feed!
                                </v-alert>
                              </v-col>
                            </v-row>
                          </v-row>
                        </div>
                      </div>
                    </div>

                    <div v-if="editedItem.type == 'doc'">
                      <v-row class="ml-0 mr-0 mb-0 mt-0">
                        <v-col cols="6" class="pb-0"
                          ><p class="text-left mb-0">
                            Inactive: {{ editedItem.inactiveItems }}
                          </p></v-col
                        >
                        <v-col cols="6" class="pb-0"
                          ><p class="text-right mb-0">
                            Total: {{  editedItem.typeData ? editedItem.typeData.length : 0 }}
                          </p></v-col
                        >
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="pt-0 pb-0" v-if="editPool && editedItem.type">
                <div v-if="editedItem.spotData && editedItem.spotData.length > 0">
                  <p class="text-left pt-1">
                    <em>
                      <b>* Spots that have disabled this pool:</b>
                      <span v-for="(spot, index) in editedItem.spotData" :key="index">
                        {{ spot.spot }} {{ index == editedItem.spotData.length - 1 ? "" : "," }}
                      </span>
                    </em>
                  </p>
                </div>
                <div v-else>
                  <p class="text-left"><em> * This Pool Is Enabled In All Spots</em></p>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="blue darken-1" text :disabled="submitting" @click="closeDialog('cancel')">Cancel</v-btn>

          <v-btn
            color="blue darken-1"
            text
            @click="
              (!editPool && editedItem.typeData.length == 0)
                ? updatePools()
                : savePool()
            "
            :disabled="!editFormIsValid || submitting || readOnlyPools"
            >{{
              formTitle == "New Backfill Pool" && editedItem.typeData.length == 0
                ? "Create"
                : "Save"
            }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>
<!-- End of EditBackfillPool.vue -->

<script>
import { mapMutations } from "vuex";
import BackfillPoolsAutocomplete from "@/components/medusa/BackfillPoolsAutocomplete";
import BackfillPoolsEditFeedUrl from "@/components/medusa/BackfillPoolsEditFeedUrl";

export default {
  props: ["addUserActivity", "closeDialog", "feedId", "updatePools"],

  components: {
    appBackfillPoolsAutocomplete: BackfillPoolsAutocomplete,
    appBackfillPoolsEditFeedUrl: BackfillPoolsEditFeedUrl
  },

  data: () => ({
    items: [],
    feedUrl: "",
    docHeaders: [
      { text: "Doc Id", value: "DocId"},
      { text: "Doc Title", value: "DocTitle", sortable: false, width: "25%" },
      { text: "Org", value: "OrgName", width: "25%" },
      { text: "Sponsored", value: "LineOrderNumber", width: "10%"},
      { text: "Date Added", value: "dateAdded", width: "9%", align: "center" },
      { text: "Date Modified", value: "ModifiedDate", width: "9%", align: "center" },
      { text: "Date Published", value: "DocPubDate", width: "10%", align: "center" },
      { text: "Remove", value: "remove", align: "start", sortable: false },
    ],
    docId: "",
    editFormIsValid: true,
    feedAssetCacheKey: "",
    feedTblHeight: 300,
    ingestionUrls: [],
    newPool: false,
    origFeedTypeData: null,
    rules: {
      min5chars(value) {
        return (value.length || "") >= 5 || `Name Must Be Longer Than 5 Characters`;
      },
      max30chars: (v) => v.length <= 30 || "Input too long!",
    },
    savedPool: null,
    submitting: false,
  }),

  computed: {
    documentUrl(){
      return this.$store.getters["auth/getApiHost"]["ups_admin"] + "/directory/document/?dashboardviewid=0&TABLENAME=itpapers.Document&FILTER[DocStatusId]=1&itpapers_Document-LIMIT=20&itpapers_Document-PAGING=1&SUBMIT=EDIT&DocId="
    },
    editedFeedIndex(){
      return this.$store.state.backfillPools.editedFeedIndex;
    },
    editedIndex() {
      return this.$store.state.backfillPools.editedIndex;
    },
    editedItem() {
      return this.$store.state.backfillPools.editedItem;
    },
    editPool() {
      return this.$store.state.backfillPools.editPool;
    },
    feedUrlDialog(){
      return this.feedUrlDialog;
    },
    formTitle() {
      return this.editedIndex === -1
        ? "New Backfill Pool"
        : "Edit Backfill Pool";
    },
    geoCacheKey(){
      return this.$store.state.backfillPools.geoCacheKey;
    },
    initialPercent() {
      return this.$store.state.backfillPools.initialPercent;
    },
    isEditing() {
      return this.$store.state.backfillPools.isEditing;
    },
    lineorderUrl(){
      return this.$store.getters["auth/getApiHost"]["ups_admin"] + "/directory/lineorder/?dashboardviewid=0&TABLENAME=itpapers.LineOrder&itpapers_LineOrder-LIMIT=50&itpapers_LineOrder-PAGING=1&SUBMIT=EDIT&LineOrderNumber="
    },
    lockedPercent() {
      return this.$store.state.backfillPools.lockedPercent;
    },
    newUnsavedPools() {
      return this.$store.state.backfillPools.newUnsavedPools;
    },
    origPools() {
      return this.$store.state.backfillPools.origPools;
    },
    pools() {
      return this.$store.state.backfillPools.pools;
    },
    readOnlyPools() {
      return this.$store.state.backfillPools.readOnlyPools;
    },
    userActivity() {
      return this.$store.state.backfillPools.userActivity;
    }
  },
  mounted(){
    //If feedId is passed as a query param and it exists, open feedUrl edit dialog
    if(this.feedId && this.editedItem.typeData.length > 0){
      let feedIndex = this.editedItem.typeData.findIndex((x) => x.feedId == this.feedId);
      if(feedIndex > -1){
        this.editFeed(feedIndex);
      } else {
        this.$store.commit("sendAlert", {
          msg: `Feed Id ${this.feedId} Cannot Be Found`,
          color: "error",
          position: "top"
        });
      }
    }
  },
  methods: {
    ...mapMutations({
      addPool: "backfillPools/addPool",
      addGeoCacheKey: "backfillPools/addGeoCacheKey",
      resetGeoCacheKey: "backfillPools/resetGeoCacheKey",
      resetUserActivity: "backfillPools/resetUserActivity",
      setEditedFeedIndex: "backfillPools/setEditedFeedIndex",
      setFeedUrlDialog: "backfillPools/setFeedUrlDialog",
      setHistoryLogLoaded: "backfillPools/setHistoryLogLoaded",
      setPools: "backfillPools/setPools",
      setUnsavedFeeds: "backfillPools/setUnsavedFeeds",
      setUnsavedGeo: "backfillPools/setUnsavedGeo"
    }),
    editFeed(index){
      this.setEditedFeedIndex(index);
      this.origFeedTypeData = JSON.parse(JSON.stringify(this.editedItem.typeData));
      for(let data of this.origFeedTypeData){
        //The above line prior to loop causes the two variables to turn into objects instead of sets
        data.countriesAdded = new Set();
        data.countriesRemoved = new Set();
      }
      this.setFeedUrlDialog(true);
    },
    /**
     * After Pools and associated data are saved, ingest new feed url assets
     */
    ingestNewFeedUrls() {
      if(this.ingestionUrls.length > 0){
        return new Promise((resolve) => {
          this.axios
            .put("/medusa/pools/ingest_new_feed_urls", {
              feed_urls: this.ingestionUrls
            }) // Get new feeds id and set it so that the value gets set without having to reload
            .then((newFeeds) => {
              let feeds = newFeeds.data.feeds;
              for (let feed of feeds){
                if(!feed.updated){
                  let poolIndex = this.pools.findIndex((x) => x.pool_id == feed.pool_id);
                  let feedIndex = this.pools[poolIndex].typeData.findIndex((x) => x.feedUrl == feed.feedUrl);
                  this.pools[poolIndex].typeData[feedIndex].feedId = feed.feedId;
                  this.pools[poolIndex].typeData[feedIndex].lastIngestionDate = feed.lastIngestionDate;
                }
              }
            })
            .catch((error) => {
              this.$store.commit("sendAlert", {
                msg: "Error Ingesting feed url(s)",
                color: "error"
              });
              console.log(error);
            })
            .finally(() => {
              resolve();
          });
        });
      } else {
        return Promise.resolve();
      }
    },
    /**
     * On Save, Compare the initial state of the Pool with the current state and log all user changes
     */
    logPoolActivity(pool) {
      let user = this.$store.state.auth.user.username;

      //If new pool added
      if (pool.pool_id == 0) {
        this.addUserActivity(
          user,
          `Pool Added: <b>Name:</b> ${pool.name} <b>Percentage:</b> ${pool.percentage} <b>Enabled:</b> ${pool.enabled} <b>Locked</b>: ${pool.locked}`
        );

        //Log TypeData if exists for new pool
        if (pool.typeDataAltered) {
          for (let data of pool.typeData) {
            if (pool.type == "doc") {
              this.addUserActivity(
                user,
                `Pool: <b>${pool.name}</b> <u>Added Doc ID:</u> <b>${data.DocId}</b>`
              );
            } else if (pool.type == "feed") {
              this.ingestionUrls.push({ feedUrl: data.feedUrl, updated: false });

              let geoType = data.geo_inclusive == 1 ? "Inclusive" : "Exclusive";
              let countries = data.selectedCountries.length > 0
                            ? `<b>Countries selected:</b> ${data.selectedCountries.toString()}`
                            : `No Countries Have Been Selected`;

              this.addUserActivity(
                user,
                `Pool: <b>${pool.name}</b> <u>Added Feed Url:</u> <b>${data.feedUrl}</b> <i>with ${data.assetCount} Items <br>Defaults:</i>
                 <b>Org: </b> ${data.defaultOrg} <b>Content Type: </b> ${data.defaultCT} <b>CTA: </b> ${data.defaultCTA} <b>Override: </b> ${data.override}
                 <br> <b>Geo Tracking:</b> ${geoType}
                 <br> <b>Targeting Notes:</b> ${data.targeting_notes}
                 <br> ${countries}`
              );
            }
          }
        } // If Pool Already exists
      } else {
        let oldPoolIndex = this.origPools.findIndex((x) => x.pool_id == pool.pool_id);
        let oldPool = this.origPools[oldPoolIndex];

        //Compare Pool Names
        if (pool.name != oldPool.name) {
          this.addUserActivity(
            user,
            `Pool name changed from <b>${oldPool.name}</b> to <b>${pool.name}`
          );
        }
        //Compare Pool TypeData
        if (pool.typeDataAltered) {
          let oldData = [];

          //If TypeData Already Exists, compare, if not, log each item
          if (oldPool.typeData) {
            //Push each associative value of oldPool into a new array for easier compare
            for (let data of oldPool.typeData) {
              if (pool.type == "doc") oldData.push(data.DocId);
              else oldData.push(data);
            }

            //Compare typeData
            for (let data of pool.typeData) {
              if (pool.type == "feed") {
                let oldFeedIndex = oldData.findIndex((x) => x.feedUrl == data.feedUrl);
                let geoType = data.geo_inclusive == 1 ? "Inclusive" : "Exclusive";

                if (oldFeedIndex == -1) {
                  let geoType = data.geo_inclusive == 1 ? "Inclusive" : "Exclusive";
                  let countries = data.selectedCountries.length > 0
                                ? `<b>Countries selected:</b> ${data.selectedCountries.toString()}`
                                : `No Countries Have Been Selected`;

                  this.addUserActivity(
                    user,
                    `Pool: <b>${pool.name}</b> <u>Added Feed Url:</u> <b>${data.feedUrl}</b> <i>with ${data.assetCount} Items <br>Defaults:</i>
                     <b>Org: </b> ${data.defaultOrg} <b>Content Type: </b> ${data.defaultCT} <b>CTA: </b> ${data.defaultCTA} <b>Override: </b> ${data.override}
                     <br> <b>Geo Tracking:</b> ${geoType}
                     <br> ${countries}`
                  );
                  //Only add feed url to need feed urls array if it is unique to backfill_pool_feed
                  if (!data.feedId) this.ingestionUrls.push({ feedUrl: data.feedUrl, updated: false });
                } else {
                  let alteredFeed = false;
                  let oldFeed = oldData[oldFeedIndex];

                  //Compare Default Feed Values
                  if(oldFeed.defaultCTA != data.defaultCTA){
                    this.addUserActivity(
                      user,
                      `Pool <b>${pool.name}</b> Feed Url <b>${data.feedUrl}</b> <u>Updated Default CTA to:</u> <b>${data.defaultCTA}</b>`
                    );
                    alteredFeed = true;
                  }
                  //Compare Conent Type
                  if(oldFeed.defaultCT != data.defaultCT){
                    this.addUserActivity(
                      user,
                      `Pool <b>${pool.name}</b> Feed Url <b>${data.feedUrl}</b> <u>Updated Default Content Type to:</u> <b>${data.defaultCT}</b>`
                    );
                    alteredFeed = true;
                  }
                  //Compare Org
                  if(oldFeed.defaultOrg != data.defaultOrg){
                    this.addUserActivity(
                      user,
                      `Pool <b>${pool.name}</b> Feed Url <b>${data.feedUrl}</b> <u>Updated Default Org to:</u> <b>${data.defaultOrg}</b>`
                    );
                    alteredFeed = true;
                  }
                  //Compare Override
                  if(oldFeed.override != data.override){
                    this.addUserActivity(
                      user,
                      `Pool <b>${pool.name}</b> Feed Url <b>${data.feedUrl}</b> <u>Updated Override to:</u> <b>${data.override}</b>`
                    );
                    alteredFeed = true;
                  }
                  //Compare Geo Type
                  if(oldFeed.geo_inclusive != data.geo_inclusive){
                    this.addUserActivity(
                      user,
                      `Pool <b>${pool.name}</b> Feed Url <b>${data.feedUrl}</b> <u>Updated Geo Tracking to:</u> <b>${geoType}</b>`
                    );
                  }
                  //Compare Geo Targeting Notes
                  if(oldFeed.targeting_notes.trim() != data.targeting_notes.trim()){
                    this.addUserActivity(
                      user,
                      `Pool <b>${pool.name}</b> Feed Url <b>${data.feedUrl}</b> <u>Updated Geo Targeting Notes to:</u> <b>${data.targeting_notes}</b>`
                    );
                  }
                  //Check if Geo Countries Added
                  if(data.countriesAdded.size > 0){
                    //convert from set to array, otherwise form won't pick it up.
                    data.countriesAdded = Array.from(data.countriesAdded);
                    this.addUserActivity(
                      user,
                      `Pool <b>${pool.name}</b> Feed Url <b>${data.feedUrl}</b>
                       <br><u>Updated ${geoType} Geo Tracking Countries to Include:</u> <b>${data.countriesAdded.toString()}</b>`
                    );
                  }
                  //Check if Geo Countries Removed
                  if(data.countriesRemoved.size > 0){
                    //convert from set to array, otherwise form won't pick it up and can't check its length.
                    data.countriesRemoved = Array.from(data.countriesRemoved);
                    this.addUserActivity(
                      user,
                      `Pool <b>${pool.name}</b> Feed Url <b>${data.feedUrl}</b>
                      <br><u>Removed Countries From Geo Tracking:</u> <b>${data.countriesRemoved.toString()}</b>`
                    );
                  }
                  //Re-ingest feed url with new default values
                  if(alteredFeed) this.ingestionUrls.push({ feedUrl: data.feedUrl, updated: true });
                }
              } else {
                if (oldData.indexOf(data.DocId) == -1) {
                  this.addUserActivity(
                    user,
                    `Pool: <b>${pool.name}</b> <u>Added Doc ID:</u> <b>${data.DocId}</b>`
                  );
                }
              }
            } //If no existing typeData
          } else {
            for (let data of pool.typeData) {
              if (pool.type == "doc") {
                this.addUserActivity(
                  user,
                  `Pool: <b>${pool.name}</b> <u>Added Doc ID:</u> <b>${data.DocId}</b>`
                );
              } else if (pool.type == "feed") {
                this.ingestionUrls.push({ feedUrl: data.feedUrl, updated: false });
                this.addUserActivity(
                  user,
                  `Pool: <b>${pool.name}</b> <u>Added Feed Url:</u> <b>${data.feedUrl}</b> <i>with ${data.assetCount} items</i>`
                );
              }
            }
          }
        }
        //Log Feed Urls & Doc IDs that have been removed
        if (pool.removedData.length > 0) {
          for (var data of pool.removedData) {
            if (data.type == "feed") {
              this.addUserActivity(
                user,
                `Pool <b>${pool.name}</b> <u>Removed Feed Url:</u> <b>${data.data["feedUrl"]}</b>`
              );
            }
            if (data.type == "doc") {
              this.addUserActivity(
                user,
                `Pool <b>${pool.name}</b> <u>Removed Doc ID:</u> <b>${data.data}</b>`
              );
            }
          }
        }
      }
    },

    purgeSpotCache(cacheName, keys, keysSupplement = null) {

      if(cacheName == "") return Promise.resolve();

      if(cacheName == "backfillPoolFeedsByGeoCache" && this.geoCacheKey.length == 0){
        return Promise.resolve();
      }
      if(cacheName == "backfillPoolFeedAssetCache" && this.feedAssetCacheKey.length == 0){
        return Promise.resolve();
      }

      this.axios
        .get("medusa/purgeMedusaCache", {
          params: {
            cache: cacheName,
            keys: keys,
            keysSupplement: keysSupplement
          }
        })
        .then((res) => {
          let color, msg;
          if (res.data.success) {
            color = "success";
            msg = cacheName + " purged: " + res.data.success;
          } else {
            color = "error";
            msg = cacheName + " failed to purge: " + res.data.error;
          }
          // Display success/error message
          this.$store.commit("sendAlert", {
            msg: msg,
            color: color
          });
        });
    },

    removeTypeData(index) {
      if (this.editedItem.pool_id == 0) {
        this.editedItem.typeData.splice(index, 1);

        if (this.editedItem.typeData.length == 0) this.editedItem.typeDataAltered = false;
        return;
      }
      let data =
        this.editedItem.type == "feed"
          ? {
              feedUrl: this.editedItem.typeData[index].feedUrl,
              feedId: this.editedItem.typeData[index].feedId
            }
          : Number(this.editedItem.typeData[index].DocId);
      let removedData = [
        {
          pool_id: Number(this.editedItem.pool_id),
          type: this.editedItem.type,
          data: data
        }
      ];
      if (this.editedItem.type == "feed") {
        this.editedItem.items -= Number(this.editedItem.typeData[index].assetCount);
      } else {
        if (this.editedItem.typeData[index].Active == true) {
          //Item Count is for active items
          this.editedItem.items -= 1;
        } else {
          this.editedItem.inactiveItems -= 1;
        }
      }
      this.editedItem.typeDataAltered = true;
      Array.prototype.push.apply(this.editedItem.removedData, removedData);
      this.editedItem.typeData.splice(index, 1);
    },

    savePool() {
      //Make sure new pool name is unique
      if (!this.editPercent && !this.editPool) {
        let uniqueName = this.pools.filter((pool) => pool.name == this.editedItem.name);
        if (uniqueName.length > 0) {
          this.$store.commit("sendAlert", {
            msg: "Pool Name Must Be Unique",
            color: "error",
            position: "top"
          });
          return;
        }
      }
      this.submitting = true;
      this.logPoolActivity(this.editedItem);

      this.axios
        .put("/medusa/pools/save_pool", {
          pool: this.editedItem,
          userActivity: this.userActivity
        })
        .then((res) => {
          this.$store.commit("sendAlert", {
            msg: "Form Saved",
            color: "success"
          });

          this.savedPool = res.data;
          let pool_id = this.savedPool['pool_id'];
          this.newPool = this.editedItem.pool_id == 0 ? true : false;
          let origName = this.pools[this.editedIndex] ? this.pools[this.editedIndex].name : this.editedItem.name;

          //Check if new pool was previously created but not saved to db to know where to push to or update the pools object
          let uniquePool = origName == this.editedItem.name
            ? this.pools.filter((pool) => pool.name == this.editedItem.name)
            : this.pools.filter((pool) => pool.name == origName);
          //Add new pool & Resort List By Percentage Desc
          if (this.newPool) {
            this.editedItem.pool_id = pool_id;
            if (uniquePool.length == 0)
              this.addPool(this.editedItem);
            else {
              let index = this.pools.indexOf(uniquePool[0]);
              Object.assign(this.pools[index], this.editedItem);
              this.origPools.push(this.editedItem);
            }
            this.setPools(
              this.pools.sort((a, b) =>
                Number(b.percentage) == Number(a.percentage)
                  ? b.name < a.name
                    ? 1
                    : -1
                  : Number(b.percentage) > Number(a.percentage)
                  ? 1
                  : -1
              )
            );

            //Remove now saved pool if exists in array
            if(this.editedIndex > -1){
              //Check to see if name was updated before checking newUnsavedPools array
              if(this.newUnsavedPools.includes(origName) && origName != this.editedItem.name){
                let index = this.newUnsavedPools.indexOf(origName);
                this.newUnsavedPools.splice(index,1);
              } else {
                  let newIndex = this.newUnsavedPools.indexOf(this.editedItem.name);
                  if (newIndex > -1)
                    this.newUnsavedPools.splice(newIndex, 1);
              }
            }
          } else {
            //Update the original pool and current pool values
            Object.assign(this.pools[this.editedIndex], this.editedItem);
          }

          //Update newly saved feed urls feed id's
          if(this.ingestionUrls.length > 0){
            for(let newFeed of this.ingestionUrls){
              let editedFeedIndex = this.editedItem.typeData.findIndex((x) => x.feedUrl == newFeed.feedUrl);

              //If feed url is brand new and has never been ingested
              if(!newFeed.updated){
                let savedFeedIndex = this.savedPool.typeData.findIndex((x) => x.feedUrl == newFeed.feedUrl);
                this.editedItem.typeData[editedFeedIndex].feedId = this.savedPool.typeData[savedFeedIndex].feedId;

                if(this.editedItem.typeData[editedFeedIndex].selectedCountries.length > 0){
                  if(!this.geoCacheKey.includes(`${this.editedItem.pool_id}|`))
                      this.addGeoCacheKey(`${this.editedItem.pool_id}|`);
                }
              } else {
                //Create cache keys for each feedId
                if (!this.feedAssetCacheKey.includes(`${this.editedItem.typeData[editedFeedIndex].feedId}|`))
                  this.feedAssetCacheKey += `${this.editedItem.typeData[editedFeedIndex].feedId}|`;
              }

            }
          }

          //Create Cache keys for medusa if geo data has been updated, add its pool id to the geo cache key
          if(this.editedItem.type == 'feed'){
            if(this.editedItem.typeData.length > 0){

              for(let data of this.editedItem.typeData){
                if(data.geo_inclusive_changed || data.countriesAdded.length > 0 || data.countriesRemoved.length > 0){
                    if(!this.geoCacheKey.includes(`${this.editedItem.pool_id}|`))
                      this.addGeoCacheKey(`${this.editedItem.pool_id}|`);
                }
              }
            }
          }
          //Reset geo related variables
          if(this.editedItem.type == "feed"){
            if(this.pools[this.editedIndex].typeData.length > 0){
              //Convert these 2 variables back to sets
              for(let data of this.pools[this.editedIndex].typeData){
                data.countriesAdded = new Set();
                data.countriesRemoved = new Set();
              }
            }
          }

        })
        .finally(() => {
          //Update doc typedata so that the added date is set for all docs for proper sorting
          if(this.editedItem.type == 'doc'){
            Object.assign(this.editedItem.typeData, JSON.parse(JSON.stringify(this.savedPool.typeData)));
          }
          //Update origPools data
          if(!this.newPool) Object.assign(this.origPools[this.editedIndex], JSON.parse(JSON.stringify(this.editedItem)));
          else Object.assign(this.origPools, JSON.parse(JSON.stringify(this.pools)));

          let cache = (this.editedItem.type == 'feed' ? 'backfillPoolFeedAssetCache' : '');
          let keySupplement = (this.editedItem.type == 'feed' ? "feedIds:" + this.feedAssetCacheKey : '');

          //Reset Values back to their defaults
          this.setUnsavedFeeds(false);
          this.setUnsavedGeo(false);
          this.setHistoryLogLoaded(false);
          this.resetUserActivity();
          this.editedItem.removedData = [];
          this.editedItem.typeDataAltered = false;
          this.submitting = false;
          this.closeDialog("update");

          Promise.all([this.ingestNewFeedUrls()]).then(() => {
            Promise.all([
              this.$store.dispatch("medusaCampaigns/purgeMedusaCache", { cacheName: "spotBackfillPoolsCache", keys: null, keysSupplement: 'all' }),
              this.purgeSpotCache('backfillPoolFeedsByGeoCache', this.geoCacheKey),
              this.purgeSpotCache(cache, null, keySupplement),
            ]).then(() => {
              //Reset Values back to their defaults
              this.resetGeoCacheKey();
              this.feedAssetCacheKey = "";
              this.savedPool = null;
              this.ingestionUrls = [];
              this.newPool = false;
            });
          });
        });
    },
    /**
     * Validate Feed Urls
     *
     * @param {string} url
     */
    validateUrl(url) {
      var re = /^(http|https):\/\/(([a-zA-Z0-9$\-_.+!*'(),;:&=]|%[0-9a-fA-F]{2})+@)?(((25[0-5]|2[0-4][0-9]|[0-1][0-9][0-9]|[1-9][0-9]|[0-9])(\.(25[0-5]|2[0-4][0-9]|[0-1][0-9][0-9]|[1-9][0-9]|[0-9])){3})|localhost|([a-zA-Z0-9\-\u00C0-\u017F]+\.)+([a-zA-Z]{2,}))(:[0-9]+)?(\/(([a-zA-Z0-9$\-_.+!*'(),;:@&=]|%[0-9a-fA-F]{2})*(\/([a-zA-Z0-9$\-_.+!*'(),;:@&=]|%[0-9a-fA-F]{2})*)*)?(\?([a-zA-Z0-9$\-_.+!*'(),;:@&=?]|%[0-9a-fA-F]{2})*)?(\\#([a-zA-Z0-9$\-_.+!*'(),;:@&=\\/?]|%[0-9a-fA-F]{2})*)?)?$/;
      if (re.test(url)) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
  ::v-deep .docsTable .v-data-table__wrapper{
    height: 45vh !important;

    tr:nth-of-type(even) {
      background-color: rgba(150, 150, 150, 0.2);
    }
  }
  #TypeDataContent ::v-deep .v-data-table td{
    font-size: 0.75rem;
  }
  #feed.row, #feedDefaults.row{
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .card-title {
    z-index: 9999;
  }
.fullForm {
  height: 74vh;
}
.shortForm {
  height: 100px;
}
#feedTable{
  border: solid 1px rgba(0,0,0,.2);
  #feedHeaders {
    font-weight: 500;
    font-style: italic;
    background: rgba(0,0,0,.8);
    color: white;
  }
  #feedData {
    max-height: 50vh;
    height: 50vh;
    overflow-y: scroll;
    #feed{
      border-top: 1px solid rgba(0,0,0,.1);
      border-bottom: 1px solid rgba(0,0,0,.5);
      &:nth-child(even) {
        background-color: rgba(150, 150, 150, 0.2);
      }
      &:hover {
        background: lighten(gray,25%);
        cursor:pointer;
        .v-alert{
          filter: brightness(85%);
        }
      }
      .v-alert{
        font-size: 14px;
        font-weight: 800;
        text-shadow: 1px 1px 2px rgba(0,0,0,0.6);
      }
      #feedDefaults, .targeting-notes{
        border-top: dashed 1px rgba(0,0,0,.2);
      }
    }
  }
}
::v-deep .read-only {
  pointer-events: none;
}
.feedDocList {
  height: 50vh;
  max-height: 50vh;
  overflow-y: scroll;
}
.item-subtitle {
  color: rgba(0, 0, 0, 0.6);
}
::v-deep .v-text-field.v-text-field--solo .v-input__control {
  min-height: 30px !important;
}
::v-deep .v-list-item--dense,
.v-list--dense .v-list-item {
  min-height: 20px !important;
}
::v-deep .v-list-item__content {
  padding-top: 0;
  padding-bottom: 0;
  min-height: 30px;
}
::v-deep .v-input__slot {
  align-items: center;
  justify-content: center;
  font-size: 12px;
  height: 15px;
  max-height: 15px !important;
  margin-bottom: 0 !important;
}
.strike {
  text-decoration: line-through;
}
::v-deep .v-list-item:nth-of-type(even) {
  background-color: rgba(150, 150, 150, 0.2);
}
@media (max-height: 815px) {
  #feedData,.feedDocList{
    max-height: 45vh !important;
    height: 45vh !important;
  }
}
@media (max-height: 730px){
  ::v-deep .docsTable .v-data-table__wrapper {
    height: 40vh !important;
  }
}
@media (max-height: 685px) {
  #feedData,.feedDocList{
    max-height: 43vh !important;
    height: 43vh !important;
  }
}
@media (max-height: 650px) {
  .fullForm {
      height: 400px;
  }
  #feedData,.feedDocList{
    max-height: 200px !important;
    height: 200px !important;
  }
  ::v-deep .docsTable .v-data-table__wrapper {
    height: 24vh !important;
  }
}
@media (max-height: 565px) {
  .fullForm {
      height: 375px;
  }
  #feedData,.feedDocList{
    max-height: 30vh !important;
    height: 30vh !important;
  }
}
@media (max-height: 535px) {
  .fullForm {
      height: 360px;
  }
}
@media (max-height: 510px) {
  .fullForm {
      height: 345px;
  }
  #feedData,.feedDocList{
    max-height: 25vh !important;
    height: 25vh !important;
  }
}
@media (max-height: 510px) {
  .fullForm {
      height: 320px;
  }
}
</style>